import './App.css';

function App() {
  return (
    <div className="App">
      <div className="header">
        <div className="title">HERSALON</div>
        <div className="subtitle">Exclusive Entrepreneurs Club </div>
</div>
      <div className="bg">        <div className="topper"></div>
      </div>
      <div className="middle">
        <h2>Lets create our dreams together!</h2>
        <h6>Join the entrepreneurs ladies salon</h6>
      </div>
      <div className="signup">
        <h1>סלון היזמיות</h1>
        <h5><b>
         ימי רביעי 
         19:00-21:00
          </b></h5>
        <h5>שלחי הודעה כדי להצטרף למפגשים</h5>
        <h5>או הצטרפי לקבוצה השקטה לקבלת עדכונים</h5>
        <div className="actions">
          <a href="https://wa.link/9xtou5" target="_blank" rel="noreferrer"><div className="whatsapp"/></a>
          <a href="mailto:sticktomypanda@gmail.com?subject=HERSALON - סלון יזמיות&body=אשמח לפרטים נוספים!" rel="noreferrer"><div className="email"/></a>
          <a href="https://chat.whatsapp.com/EEtYg5mER17D0IHacTZU7D" target="_blank" rel="noreferrer"><div className="group"/></a>
        </div>
      </div>
    </div>
  );
}

export default App;
